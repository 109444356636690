import styled from 'styled-components'
import { useContext } from 'preact/hooks'
import { Link } from 'preact-router/match'
import { route } from 'preact-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { DonamaidContext } from '../../../context/DonamaidContext'

import Section from '../../sections'
import Select from '../../select'
import PopularPages from './PopularPages'

const FooterSection = styled(Section)`
  background-color: #603285;
`

const ContentSection = styled(Section)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .illustration {
    text-align: start;
    width: 100%;
    color: #e2e2e2;
    text-decoration: none;
    font-size: 12px;
    margin-top: 8px;
  }
`

const Social = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .media {
    display: flex;
    flex-direction: row;

    a {
      width: 24px;

      :not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .media {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .stores {
    > *:first-child {
      margin-right: 8px;
    }

    img {
      width: 75px;
      height: 100%;

      @media(min-width: 350px) {
        width: 100px;
        height: 100%;
      }
    }
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .donamaid, .services, .cities {
    display: flex;
    flex-direction: column;
    color: #fff;

    h1 {
      font-size: 16px;
      margin: 0;
      margin-bottom: 8px;
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      padding: 12px 0;
    }

    a {
      padding: 8px 0;
    }
  }

  .services, .cities {
    margin-top: 24px;

    @media(min-width: 768px) {
      margin-top: 0;
    }
  }

  .cities {
    min-width: 240px;
  }

  .cities > .popular-cities {
    display: none;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
    }
  }

  .donamaid > .support {
    font-size: 12px;
  }

  @media(min-width: 768px) {
    flex-direction: row;
    
    > div {
      margin-top: 0;
      margin-right: 72px;
    }
  }

  @media (min-width: 1024px) {
    > div {
      margin-right: 160px;
    }
  }
`

const Footer = () => {
  const [state] = useContext(DonamaidContext)

  function cities() {
    return state.cities.map(city => {
      return { value: city.id, label: city.displayName }
    })
  }
  function handleCitySelection(e) {
    route(`/cidades/${e.target.value.toLowerCase().replaceAll(' ', '-')}`, true);
  }

  return (
    <FooterSection fullWidth>
      <ContentSection>
        <Content>
          <div className="donamaid">
            <h1>Donamaid</h1>

            <a href='https://cliente.donamaid.com'>Entrar</a>
            <Link href='/seja-diarista-com-a-donamaid'>Quero ser diarista</Link>
            <a href='https://blog.donamaid.com'>Blog</a>
            <a href='https://donamaid-suporte.crisp.help/pt/'>Ajuda</a>
            <Link href='/termos-de-uso'>Termos de uso</Link>
            <Link href='/politica-de-privacidade'>Política de privacidade</Link>
            <p className="support">Horário de atendimento de <b>segunda a sexta dás 8h às 18h</b> e nos <b>sábados das 8h às 14h</b>.</p>
            <p className="support">Para suporte via WhatsApp chame em<b> +5553999525716</b>.</p>

          </div>

          <div className="services">
            <h1>Serviços</h1>

            <Link href='/servicos'>Limpeza residencial</Link>
            <Link href='/servicos'>Limpeza empresarial</Link>
            <Link href='/servicos'>Limpeza mensal</Link>
            <Link href='/servicos/assinatura'>Limpeza semanal</Link>
            <Link href='/servicos/assinatura'>Limpeza quinzenal</Link>
          </div>

          <div className="cities">
            <h1>Cidades Atendidas</h1>

            <div className="popular-cities">
              <Link href='/cidades/pelotas'>Pelotas - RS</Link>
              <Link href='/cidades/porto-alegre'>Porto Alegre - RS</Link>
              <Link href='/cidades/curitiba'>Curitiba - RS</Link>
              <Link href='/cidades/joinville'>Joinville - SC</Link>
              <Link href='/cidades/belo-horizonte'>Belo Horizonte - MG</Link>
              <Link href='/cidades/sao-paulo'>São Paulo - SP</Link>
              <Link href='/cidades/londrina'>Londrina - PR</Link>
            </div>

            <Select
              options={cities()}
              adornmentImgPath="/icons/place_filled.svg"
              placeholder="Selecione uma cidade"
              onChange={handleCitySelection}
              compact={true}
            />
          </div>
        </Content>

        <PopularPages />

        <Social>
          <div className="media">
            <a href="https://www.facebook.com/donamaidbrasil" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/facebook.svg' alt="Facebook da Donamaid" title="Facebook da Donamaid" />
            </a>

            <a href="https://www.linkedin.com/company/donamaid/" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/linkedin.svg' alt="Linkedin da Donamaid" title="Linkedin da Donamaid" />
            </a>

            <a href="https://www.instagram.com/donamaid" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/instagram.svg' alt="Instagram da Donamaid" title="Instagram da Donamaid" />
            </a>

            <a href="https://wa.me/message/WOW57W65QCYSM1" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/whatsapp.svg' alt="Whatsapp Suporte Donamaid" title="Whatsapp Suporte Donamaid" />
            </a>
          </div>

          <div className="stores">
            <a href="https://apps.apple.com/br/app/donamaid/id1519670575" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/app-store.png' title="Disponível na App Store" alt="Aplicativo de faxina disponível na App Store" />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.donamaid.donamaid" target="_blank" rel="noreferrer">
              <LazyLoadImage src='../../assets/logos/social/google-play.png' title="Disponível no Google Play" alt="Aplicativo de faxina disponível no Google Play" />
            </a>
          </div>
        </Social>

        <a className='illustration' href="https://storyset.com/people" target="_blank" rel="noreferrer">People illustrations by Storyset</a>
      </ContentSection>
    </FooterSection>
  )
}

export default Footer
